import React from 'react';
import {
    Typography, Box, Grid, Button,
} from '@mui/material';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Layout from '../../layout/Layout';
import ServiceIntroduction from '../../components/services/ServiceIntroduction';
import HomeConsultation from '../../components/home/HomeConsultation';
import colors from '../../theme/colors';

const infoWrapper = {
    maxWidth: '1440px',
    width: '100%',
    padding: '48px 136px 0px',
    minHeight: '418px',
};
const customTextTypography = {
    fontFamily: 'Nunito',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '39px',
    letterSpacing: '0em',
    textAlign: 'left',
};
const customCircleRoundedIcon = {
    color: colors.lightGreen,
    fontSize: 'small',
    transform: 'scale(0.75)',
    marginRight: '8px',
};
const arrowIcon = {
    color: colors.green,
    fontSize: 'medium',
    marginLeft: '8px',
    fontWeight: 'light',
};
const violetContainer = {
    padding: '37px',
    minHeight: '308px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
    background: `linear-gradient(180deg, #26192C -31.93%, ${colors.violet} 54.16%)`,
    border: '2px solid transparent',
    borderRadius: '10px',
    maxWidth: '339px',
};

const serviceIntroductionData = {
    title: 'SAP',
    firstParagraph: 'Infraestructura dedicada para SAP Business Applications en la nube. Diferentes escenarios para tus sistemas SAP Business One, SAP ERP y SAP S/4Hana con hardware certificado.',
    gatsbyImg: (<StaticImage src="../../images/sap_icon.svg" alt="SAP" />),
    isButton: true,
    buttonText: 'Contactanos',
    buttonLinkTo: '/contact_us',
};

const Sap = () => (
    <Layout>
        <ServiceIntroduction serviceIntroductionData={serviceIntroductionData} />
        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: colors.darkViolet,
            }}
        >
            <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '48px 24px 0px' }, ...infoWrapper })}>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        sx={(theme) => ({
                            [theme.breakpoints.up('lg')]: { paddingRight: '90px' },
                            [theme.breakpoints.down('md')]: { paddingBottom: '32px' },
                        })}
                    >
                        <Box mb={2}>
                            <Typography variant="h4" sx={{ ...customTextTypography }} mb={1}>
                                Ofrecemos servicios de consultoría con
                            </Typography>
                            <Typography variant="h5" style={{ lineHeight: '32px' }}>
                                <CircleRoundedIcon style={{ ...customCircleRoundedIcon }} />
                                Proyectos de migración y upgrade
                            </Typography>
                            <Typography variant="h5" style={{ lineHeight: '32px' }}>
                                <CircleRoundedIcon style={{ ...customCircleRoundedIcon }} />
                                Mantenimiento de tus aplicaciones SAP
                            </Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="h4" sx={{ ...customTextTypography }} mb={1}>
                                Perfiles de nuestros consultores
                            </Typography>
                            <Typography variant="h5" style={{ lineHeight: '32px' }}>
                                <CircleRoundedIcon style={{ ...customCircleRoundedIcon }} />
                                SAP Basis
                            </Typography>
                            <Typography variant="h5" style={{ lineHeight: '32px' }}>
                                <CircleRoundedIcon style={{ ...customCircleRoundedIcon }} />
                                Desarrolladores ABAP
                            </Typography>
                            <Typography variant="h5" style={{ lineHeight: '32px' }}>
                                <CircleRoundedIcon style={{ ...customCircleRoundedIcon }} />
                                Consultores Funcionales
                            </Typography>
                        </Box>
                        <Typography variant="h5">
                            Disponemos de licencias SLES (SUSE Linux Enterprise Server), sistema operativo recomendado para ambientes SAP HANA.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ ...violetContainer }}>
                            <Typography sx={{ ...customTextTypography }} style={{ lineHeight: '27px', maxWidth: '212px' }}>
                                Servicios Relacionados
                            </Typography>
                            <Typography variant="h4" style={{ fontWeight: 400, lineHeight: '28px' }}>
                                Basado en nuestra solución de Private Cloud, esquema recomendado para esta solución.
                            </Typography>
                            <Button
                                component={Link}
                                to="/solutions/private_cloud"
                                aria-label="Ver Private Cloud"
                                sx={{ textDecoration: 'none', marginLeft: '-8px' }}
                            >
                                <Typography variant="h5">
                                    Ver Private cloud
                                    <DoubleArrowIcon sx={{ ...arrowIcon }} />
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <HomeConsultation />
    </Layout>
);

export default Sap;
